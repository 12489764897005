import DefaultHeader from "../../components/header/DefaultHeader";
import Footer from "../../components/footer";
import MobileMenu from "../../components/mobile-menu";
import MetaData from "../../components/MetaData";
import JoditPreview from '../../components/JoditPreview';

const About = ({ settings, content, category }) => {
  const metaInformation = {
      title: settings?.company_name + ' | ' + content?.title,
  };

  return (
    <>
    <MetaData meta={metaInformation} />
      <DefaultHeader settings={settings} category={category} />

      <MobileMenu settings={settings} category={category} />

      <section className="breadcumb-section2 p-0" style={{ backgroundImage: `url(${content?.image})` }}>
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="breadcumb-style1">
                <h2 className="title">{content?.title}</h2>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="our-about pb90">
        <div className="container">
          <div className="row" data-aos="fade-up" data-aos-delay="300">
            <JoditPreview content={content?.content} />
          </div>
        </div>
      </section>

      <section className="footer-style1 at-home4 pt60 pb-0">
        <Footer settings={settings} />
      </section>
    </>
  );
};

export default About;

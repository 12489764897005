import JoditEditor from 'jodit-pro-react';

const JoditPreview = ({ content }) => {
  const config = {
    license: 'F132X-2LLE2-BDNMP-VKO3X',
    readonly: true,
    showCharsCounter: false,
    showWordsCounter: false,
    showXPathInStatusbar: false,
    toolbar: false,
    statusbar: false,
    height: 'auto',
    minHeight: 'auto',
    width: '100%',
    buttons: [],
    disablePlugins: 'drag-and-drop,drag-and-drop-element,file,image,image-processor,image-properties,media',
    allowResizeY: false,
    style: {
      background: 'transparent',
      border: 'none',
      padding: '0',
      margin: '0',
      width: '100%'
    },
    editHTMLDocumentMode: false,
    removeButtons: ['source', 'fullsize', 'about', 'dots'],
    theme: 'inline',
    iframe: false,
    className: 'jodit-container_border-less'
  };

  return (
    <>
      <style jsx global>{`
        .jodit,
        .jodit-container,
        .jodit-workplace,
        .jodit-wysiwyg,
        .jodit-status-bar,
        .jodit-toolbar,
        .jodit-container:not(.jodit_inline) {
          border: 0 !important;
          border-width: 0 !important;
          border-color: transparent !important;
          box-shadow: none !important;
          outline: none !important;
          width: 100% !important;
          max-width: 100% !important;
        }

        .jodit-container {
          display: block !important;
          width: 100% !important;
        }

        .jodit-workplace {
          display: block !important;
          width: 100% !important;
        }

        .jodit-wysiwyg {
          display: block !important;
          width: 100% !important;
          padding: 0 !important;
        }

        .ui-content {
          width: 100% !important;
        }
      `}</style>

      <div style={{ border: 'none', width: '100%' }}>
        <JoditEditor 
          config={config} 
          value={content}
          onBlur={() => {}}
          onChange={() => {}}
          style={{ width: '100%' }}
        />
      </div>
    </>
  );
};

export default JoditPreview; 
import Footer from "../../components/footer";
import Blog from "../../components/Blog";
import MobileMenu from "../../components/mobile-menu";
import FeaturedListings from "../../components/FeatuerdListings";
import Header from "../../components/Header";
import Hero from "../../components/Hero";
import MetaData from "../../components/MetaData";
import ApartmentTypes from "../../components/ApartmentType";
import FilterWithProperties from "../../components/filter-with-property"
import { useState, useEffect } from "react";
import {useLocale} from "../../context/locale";

const Home = ({ settings }) => {
  const metaInformation = {
    title: settings?.company_name + " | Home",
  }

  const [ relatedPost, setRelatedPost ] = useState([])
  const { messages, locale } = useLocale();

  useEffect(() => {
    const getRelatedPost = async() => {
      try {
          const response = await axios.post('/api/relatedpost', {
              locale: locale
          })
          setRelatedPost(response.data.blogs)
      }
      catch(error) {
          console.log(error)
      }
    }
    getRelatedPost()
  },[])

  return (
    <>
    <MetaData meta={metaInformation} />
      <Header settings={settings} />

      <MobileMenu settings={settings} />

      <div className="banner-wrapper main-banner-wrapper  position-relative banner-style-one">
        <Hero />
      </div>

      <section className="pb60 bgc-f7">
        <div className="container mb60">
          <div className="row">
          <div data-aos="fade-up" data-aos-delay="0">
            <div className="col-md-12 text-center mb60 mb0-md">
              <h3 className="slogan">{messages.home?.lets_find_your_dream}</h3>
            </div>
          </div>
            <div className="col-lg-12 pt60 pt0-md">
              <FilterWithProperties />
            </div>
          </div>
        </div>
        
        <div className="container">
          <div className="row" data-aos="fade-up" data-aos-delay="0">
            <div className="col-lg-6 mx-auto">
              <div className="main-title2 text-center">
                <h2 className="title">{messages.home?.explore_apartment_types}</h2>
              </div>
            </div>
          </div>

          <div className="row" data-aos="fade-up" data-aos-delay="300">
            <ApartmentTypes />
          </div>
        </div>
      </section>

      <section className="pt-0 pb10 pb30-md">
        <div className="container">
          <div className="row justify-content-between align-items-center">
            <div className="col-auto">
              <div className="main-title">
                <h2 className="title">{messages?.home?.properties_for_turkish_citizenship}</h2>
              </div>
            </div>

            <div className="col-auto mb30">
              <div className="row align-items-center justify-content-center">
                <div className="col-auto">
                  <button className="featured-prev__active_1 swiper_button">
                    <i className="far fa-arrow-left-long" />
                  </button>
                </div>

                <div className="col-auto">
                  <div className="pagination_1 swiper--pagination_1 featured-pagination__active_1" />
                </div>

                <div className="col-auto">
                  <button className="featured-next__active_1 swiper_button">
                    <i className="far fa-arrow-right-long" />
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-12" data-aos="fade-up" data-aos-delay="200">
              <div className="feature-listing-slider">
                <FeaturedListings id="12" slideid="1" />
              </div>
            </div>
          </div>
        </div>
      </section>

       <section className="pt-0 pb10 pb50-md">
        <div className="container">
          <div className="row justify-content-between align-items-center">
            <div className="col-auto">
              <div className="main-title">
                <h2 className="title">{messages?.home?.special_projects}</h2>
              </div>
            </div>

            <div className="col-auto mb30">
              <div className="row align-items-center justify-content-center">
                <div className="col-auto">
                  <button className="featured-prev__active_2 swiper_button">
                    <i className="far fa-arrow-left-long" />
                  </button>
                </div>

                <div className="col-auto">
                  <div className="pagination_1 swiper--pagination_2 featured-pagination__active_2" />
                </div>

                <div className="col-auto">
                  <button className="featured-next__active_2 swiper_button">
                    <i className="far fa-arrow-right-long" />
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-12" data-aos="fade-up" data-aos-delay="200">
              <div className="feature-listing-slider_2">
                <FeaturedListings id="13" slideid="2" />
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="container-fluid pb50-md looking-to-buy pt90 pb90 mb30">
        <div className="row">
          <div className="col-12 looking">
            <h2>{messages?.home?.looking_header}</h2>
            <h3>{messages?.home?.looking_desc}</h3>
          </div>
          <div className="col-md-6 value-property">
            <h4>{messages?.home?.value_a_property}</h4>
            <p>{messages?.home?.value_a_property_desc}</p>
            <a href={`${locale != 'en' ? '/'+locale : ''}/start-valuation`} className="button" title={messages?.home?.start_valution}>
                {messages?.home?.start_valution}
            </a>
          </div>
          <div className="col-md-6 sell-you-property">
            <h4>{messages?.home?.sell_you_property}</h4>
            <p>{messages?.home?.sell_you_property_desc}</p>
            <a href={`${locale != 'en' ? '/'+locale : ''}/begin-sale-process`} className="button" title={messages?.home?.begin_sale_process}>
                {messages?.home?.begin_sale_process}
            </a>
            </div>
        </div>
      </div>

      <section className="pt-0 pb90 pb50-md">
        <div className="container">
          <div className="row  justify-content-between align-items-center">
            <div className="col-auto">
              <div className="main-title">
                <h2 className="title">{messages?.home?.golden_visa_properties}</h2>
              </div>
            </div>

            <div className="col-auto mb30">
              <div className="row align-items-center justify-content-center">
                <div className="col-auto">
                  <button className="featured-prev__active_3 swiper_button">
                    <i className="far fa-arrow-left-long" />
                  </button>
                </div>

                <div className="col-auto">
                  <div className="pagination_1 swiper--pagination_3 featured-pagination__active_3" />
                </div>

                <div className="col-auto">
                  <button className="featured-next__active_3 swiper_button">
                    <i className="far fa-arrow-right-long" />
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-12" data-aos="fade-up" data-aos-delay="200">
              <div className="feature-listing-slider_3">
                <FeaturedListings id="14" slideid="3" />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="pt-0 pb90 pb30-md">
        <div className="container">
          <div className="row mt30">
            <div
              className="col-lg-6 m-auto"
              data-aos="fade-up"
              data-aos-delay="0"
            >
              <div className="main-title text-start text-md-center">
                <h2 className="title">{messages?.home?.from_our_blog}</h2>
                <p className="paragraph">
                    {messages?.home?.from_our_blog_desc}
                </p>
              </div>
            </div>
          </div>

          <div className="row" data-aos="fade-up" data-aos-delay="300">
            <Blog relatedPost={relatedPost} />
          </div>
        </div>
      </section>

      <section className="footer-style1 at-home4 pt60 pb-0">
        <Footer settings={settings} />
      </section>
    </>
  );
};

export default Home;

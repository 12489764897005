import Details from "../../../components/blog-single/Details";
import Blog from "../../../components/blog-single/Blog";
import DefaultHeader from "../../../components/header/DefaultHeader";
import Footer from "../../../components/footer";
import MobileMenu from "../../../components/mobile-menu";
import MetaData from "../../../components/MetaData";
import { useEffect, useState } from "react";
import JoditPreview from '../../../components/JoditPreview';

const BlogSingle = ({ settings, blog, category }) => {
  const [ relatedPost, setRelatedPost ] = useState([])
  const metaInformation = {
      title: settings?.company_name + ' | ' + blog?.title,
  };

  useEffect(() => {
    const getRelatedPost = async() => {
      try {
          const response = await axios.get('/api/relatedpost')
          setRelatedPost(response.data.blogs)
      }
      catch(error) {
          console.log(error)
      }
    }
    getRelatedPost()
  },[])

  return (
    <>
      
      <MetaData meta={metaInformation} />
      <DefaultHeader settings={settings} />

      <MobileMenu settings={settings} />

      <section className="our-blog pt50">

        <Details blog={blog} />

        <div className="container">
          <div className="roww" data-aos="fade-up" data-aos-delay="500">
            <div className="col-xl-12">
              <div className="ui-content mt40 mb60">
                <JoditPreview content={blog?.content} />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="pb90 pb20-md pt-0">
        <div className="container">
          <div className="row">
            <div
              className="col-lg-6 m-auto"
              data-aos="fade-up"
              data-aos-delay="0"
            >
              <div className="main-title text-start text-md-center">
                <h2 className="title">Related Posts</h2>
                <p className="paragraph">
                  Aliquam lacinia diam quis lacus euismod
                </p>
              </div>
            </div>
          </div>

          <div className="row" data-aos="fade-up" data-aos-delay="300">
            <Blog relatedPost={relatedPost} />
          </div>
        </div>
      </section>

     <section className="footer-style1 at-home4 pt60 pb-0">
        <Footer settings={settings} />
     </section>
    </>
  );
};

export default BlogSingle;
